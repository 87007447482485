import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactContainer from "../components/contactInfo"
import ThankYou from "../components/formSubmissionThankYou"
import About from "../components/aboutSection"

const Success = () => {
  return (
     <Layout>
      <SEO title="Success" />
      <ThankYou />
      <ContactContainer />
      <About />
    </Layout>
  )
}

export default Success
