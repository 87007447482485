import React from 'react'
import styled from "styled-components"

const FormSubmissionThankYou = () => {
  return (
    <ParagraphContainer>
      <Header>
        Thank you for submitting your reservaiton inquiry.
      </Header>
      <MainContainer>
        We will look over your request and get back to you as soon as possible. 
      </MainContainer>
    </ParagraphContainer>
  )
}

export default FormSubmissionThankYou

const ParagraphContainer = styled.div`
  display:flex;
  flex-direction: column;
  padding: 5rem calc((100vw - 1300px) / 2);
  font-size: clamp(1.2rem, 5vw, 1.75rem);
  align-items: center;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 0;
`
const Header = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: left;
`

const MainContainer = styled.p`
  font-size: 1rem;
  text-align: left;
`